// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

var $ = require('jquery');
require('slick-carousel');
const lodash = require('lodash');

//global


// jQuery( function() {
//   jQuery( ".datefield" ).datepicker();
// } );


(function ($) {
  // Needed because of clash with jQuery and Bootstrap modal
  jQuery.noConflict();

  // Help centre specific

  //Reset Bootstrap Modals on close
  function setupResetBSModal() {
    $('.modal').not('.modal--onboarding').on('hidden.bs.modal', function() {
      $(this).find('.modal-content').html('');
    });
  }// /setupResetBSModal()
  setupResetBSModal();


  // Change location
  $(document).on('click', '.lh-contact__selector-btn', function(e) {
    e.preventDefault();
    var $modalPopup = $('#modalPopup'),
        $modalSpinner = $modalPopup.find('#modalSpinner'),
        $modalPopupBody = $('#modalPopupBody');

        $modalSpinner.html(General.generateSpinnerElement(10, 5, 16, '50%', '50%'));
        $modalPopup.modal('show');

      $.get('/change-country')
      .done(function(data){
          $modalPopupBody.html(lodash.unescape(lodash.escape(data)));
          $modalSpinner.html("");
      }).fail(function(){
          $modalSpinner.html("");
      });
  });

  $(document).on('click', '.locale-selection__link', function(e) {
    e.preventDefault();
    var url = $(this).attr('href');
    var languageContainer = $('.lh-contact__details');

    var $modalPopup = $('#modalPopup'),
        $modalSpinner = $modalPopup.find('#modalSpinner'),
        $modalPopupBody = $('#modalPopupBody');

        $modalPopupBody.html(General.generateSpinnerElement(10, 5, 16, '50%', '50%'));

    $.get(url)
    .done(function(data) {
      languageContainer.html(lodash.unescape(lodash.escape(data)));
      $modalPopup.modal("hide");
      $modalSpinner.html("");
    })
    .fail(function(data) {
      $modalSpinner.html("");
    });
  });

  $(document).on('click', '.ext-load-form', function(e) {
    e.preventDefault();
    var lastPos = Number($('.ext-link-tile').last().data('list-pos'));
    var url = $(this).attr('href');
    if (!isNaN(lastPos)) {
      lastPos += 1;
      if (url.includes('?')) {
        url = (url + '&position=' + lastPos);
      } else {
        url = (url + '?position=' + lastPos);
      }
    }

    $.ajax({
      method: "GET",
      url: url,
      success: function(data) {
        $('.external-link-list').append(lodash.unescape(lodash.escape(data)));
        if (!isNaN(lastPos) && lastPos >= 4) {
          $('.external-add').hide();
        }
      }
    })
  })

  // END Help centre specific

  // Outdated Content
  $('.outdated-delete').on('click', function(e){
    e.preventDefault()
    var row = $(this).closest('tr'),
        url = $(this).attr('href');

    if(confirm('Are you sure you want to dismiss this?') == true) {
      $.post({
        url: url
      })
      .done(function(data) {
        row.fadeOut();
      })
      .fail(function(data) {
        console.log('Error');
      });
    }
  })

  // END Outdated Content

  // CMS clone Modal
  $(document).on('click', '.ajax-clone-edition', function(e) {
    e.preventDefault();
      var $modalPopup = $('#modalPopup'),
        $modalSpinner = $modalPopup.find('#modalSpinner'),
        $modalPopupBody = $('#modalPopupBody'),
        $modalError = $modalPopup.find('.error'),
        url = $(this).attr('href');

        $modalSpinner.html(General.generateSpinnerElement(10, 5, 16, '50%', '50%'));
        $modalPopup.modal('show');
    $.get(url)
      .done(function(data) {
        $modalPopupBody.html(lodash.unescape(lodash.escape(data)));
        $modalSpinner.html("");
        initSelect2();
      }).fail(function(data) {
        $modalSpinner.html("");
        $modalPopupBody.html(lodash.unescape(lodash.escape(data)));
        $modalError.removeClass('hide');

      });
  });

  $(document).on('submit', '.ajax-clone-form', function(e){
    e.preventDefault();
    var $modalPopup = $('#modalPopup'),
        $modalSpinner = $modalPopup.find('#modalSpinner'),
        $modalPopupBody = $('#modalPopupBody'),
        $modalError = $modalPopup.find('.error'),
        $modalSuccess = $modalPopup.find('.success'),
        url = $(this).attr('action'),
        data = $(this).serializeArray();
    if (confirmDelete('Are you sure you want to clone this item?') == true) {
      $.post({
        url: url,
        data: data,
      })
      .done(function(data) {
        $modalError.addClass('hide');
        $modalSuccess.html(lodash.unescape(lodash.escape(data)));
        $modalSuccess.removeClass('hide');
      })
      .fail(function(data) {
        $modalSuccess.addClass('hide');
        $modalError.html(lodash.unescape(lodash.escape(data.responseText)));
        $modalError.removeClass('hide');
      })
    }
  });
  function initSelect2() {
    $('.multiple-select').select2({
      closeOnSelect: false,
      containerCssClass: ':all:',
    });
    $('.multiple-select').on("select2:select", function (e) {
      var element = e.params.data.element;
      $(element).detach();
      $(this).append($(element));
      $(this).trigger("change");
    });
  }
  function confirmDelete(text) {
    var result = window.confirm(text);
    return result;
  }
  // Homepage onboarding
  function initOnbording() {
    var $modalPopup = $('.modal--onboarding');
    $modalPopup.modal('show');
    $modalPopup.on('hidden.bs.modal', function(e){
      e.preventDefault();
      var $modalContent = $(this).find('.modal-content'),
          $checkbox = $(this).find('.onboarding__input'),
          url = $checkbox.data('endpoint');
      if($checkbox.prop('checked')) {
        $.post({
          url: url
        })
        .done(function() {
          $modalContent.html('');
        });
      } else {
        $modalContent.html('');
      }
    });
  }

  initOnbording();

  // NEXT GEN
  function initCareerPaths() {
    var $tab = $('.career-paths__tab-item'),
        $content = $('.career-paths__tab-content');

    $tab.on('click', function(e) {
      var dest = $(this).data('dest')
      $tab.removeClass('career-paths__tab-item--active');
      $content.removeClass('career-paths__tab-content--active');

      $(this).addClass('career-paths__tab-item--active');
      $('#'+ dest).addClass('career-paths__tab-content--active')
    });
  }
  if($('.career-paths').length > 0) {
    initCareerPaths();
  }

  // NEXT GEN Registration
  function initNextGenRegistration() {
    // Toggle eligibility button if consent checkbox is checked
    var $consentCheckbox = $('#next-gen-register__consent-checkbox'),
        isCheckedConsent = $consentCheckbox.prop('checked'),
        $termsCheckbox = $('#next-gen-register__terms-checkbox'),
        isCheckedTerms = $termsCheckbox.prop('checked');
    $('.next-gen').on('change', '#next-gen-register__consent-checkbox', function() {
      var $eligibilityButton = $(this).closest('.next-gen-form--validate-email').find('.next-gen-validate__btn');
      isCheckedConsent = !isCheckedConsent;
      $eligibilityButton.prop('disabled', !isCheckedConsent || !isCheckedTerms);
    });
    $('.next-gen').on('change', '#next-gen-register__terms-checkbox', function() {
      var $eligibilityButton = $(this).closest('.next-gen-form--validate-email').find('.next-gen-validate__btn');
      isCheckedTerms = !isCheckedTerms;
      $eligibilityButton.prop('disabled', !isCheckedTerms || !isCheckedConsent);
    });


    // Check if user is logged in
    function showUserIsLoggedInModal() {
      var $modalPopup = $('#modalPopup'),
        $modalPopupBody = $('#modalPopupBody'),
        $modalContent = $('.next-gen').find('.next-gen__modal-content').html();
      $modalPopupBody.html(lodash.unescape(lodash.escape($modalContent)));
      $modalPopup.modal('show');
    }

    // Validation of user email
    $('.next-gen').on('click', '.next-gen-validate__btn', function(e) {
      e.preventDefault();
      var $container = $('.next-gen__form-container-content'),
      $form = $(this).closest('.next-gen-form--validate-email'),
      url = $form.attr('action');
      $.post({
        url: url,
        data: $form.serialize()
      })
      .done(function(data) {
        $container.html(lodash.unescape(lodash.escape(data)));
        var isLoggedIn = $container.find('.next-gen-form__isLoggedIn').length > 0,
            isSuccess = $container.find('.next-gen__form-errors .success').length > 0;

        if(isLoggedIn && isSuccess) {
          showUserIsLoggedInModal()
        }
      })
      .fail(function(data) {
        $container.html(lodash.unescape(lodash.escape(data)))
      });
    });


    // Move on to the registration form
    $('.next-gen').on('click', '.next-gen-register__btn--continue', function(e) {
      e.preventDefault();
      var $container = $('.next-gen__form-container-content'),
        emailAddress = $(this).data('email'),
        url = '/next-gen/register?email=' + encodeURIComponent(emailAddress);

      if(emailAddress){
        $.get({
          url: url
        })
        .done(function(data) {
          $container.html(lodash.unescape(lodash.escape(data)))
        })
        .fail(function(data) {
          $container.html(lodash.unescape(lodash.escape(data)))
        });
      }
    });

        // Move on to the registration form
    $('.next-gen').on('click', '.link_emailTemplate', function(e) {
          e.preventDefault();

          var $container = $('.next-gen__form-container-content'),

            url = '/next-gen/email-template';

          if(url){
            $.get({
              url: url
            })
            .done(function(data) {
              $container.html(lodash.unescape(lodash.escape(data)))
            })
            .fail(function(data) {
              $container.html(lodash.unescape(lodash.escape(data)))
            });
          }
        });

    // Registration form submission
    $('.next-gen').on('click', '.next-gen-register__btn', function(e) {
      e.preventDefault();
      var $container = $('.next-gen__form-container-content'),
        $form = $(this).closest('.next-gen-register__form'),
        url = $form.attr('action');
      $.post({
        url: url,
        data: $form.serialize()
      })
      .done(function(data) {
        $container.html(lodash.unescape(lodash.escape(data)))
      })
      .fail(function(data) {
        $container.html(lodash.unescape(lodash.escape(data)))
      });
    });

    // Password toggle
    var showPassword = false;
    $('.next-gen').on('click', '.next-gen__password-toggle', function() {
      var $input = $(this).siblings('.next-gen__form-input--password'),
        $showText = $(this).data('show'),
        $hideText = $(this).data('hide');

      showPassword = !showPassword;
      $input.attr('type', showPassword ? 'text' : 'password');
      $(this).text( showPassword ? $hideText : $showText );
    });

    // Cancel modal
    function initModalConfirmEvents() {
      $('.next-gen').on('click',  '.next-gen-register__btn--confirm', function(e) {
        e.preventDefault();
        location.reload();
      });
    }
    // Cancel prompt
    $('.next-gen').on('click',  '.next-gen-cancel__btn', function(e) {
      e.preventDefault();

      var $modalPopup = $('#modalPopup'),
          $modalPopupBody = $('#modalPopupBody'),
          $modalContent = $('.next-gen').find('.next-gen__modal-content').html();
      $modalPopupBody.html(lodash.unescape(lodash.escape($modalContent)));
      $modalPopup.modal('show');
      initModalConfirmEvents();
    });

    // END NEXT GEN Registration
  }
  if($('.next-gen__form').length > 0) {
    initNextGenRegistration();
  }

  // END NEXT GEN

  // PREFERRED SUCCESS 2021
  function initPreferredSuccessRegistration() {
    $('.btn-preferred-success-register').on('click', function(e) {
      e.preventDefault();
      var $container = $('.ps-hero__content-template'),
      url = $(this).attr('href');

      $container.html(General.generateSpinnerElement(10, 5, 16, '50%', '50%'))
      $.post({
        url: url
      })
      .done(function(data) {
        $container.html(lodash.unescape(lodash.escape(data)))
        if($('.ps-hero__content-template [data-redirect]').length > 0){
          checkToRedirect();
        }
      })
      .fail(function(data) {
        $container.html(lodash.unescape(lodash.escape(data)));
      });
    });
    if($('.ps-hero__content-template [data-redirect]').length > 0){
      checkToRedirect();
    }
  }
  function checkToRedirect() {
    var url = $('.ps-hero__content-template [data-redirect]').data('redirect');
    setTimeout(function() {
      window.location.href = url;
    }, 60000);
  }
  if($('.ps-hero__content-template').length > 0) {
    initPreferredSuccessRegistration();
  }
  // END PREFERRED SUCCESS 2021
}
)(jQuery);

$(function() {

  // Debounce function for window resize
  // Will ensure that resize functions will only fire in the end of resize
  var debounce = function( f,t ) {
    var self = this;
    var debounceTimeout;
    return function(){
      if(debounceTimeout){
        clearTimeout( debounceTimeout );
      }
      debounceTimeout = setTimeout( function(){
        f.call(self);
      },t)
    };
  };

  // Resize function
  function resize(){
    var ww = $(window).width();
    if( ww > 767 ) {
      $(this).closest("div.row").find(".accordion-tabs.active").removeClass('active');
      $(this).closest("div.row").find(".panel.show").removeClass("show");
      $(this).closest("div.row").find(".accordion-tabs").first().addClass("active");
      $(this).closest("div.row").find(".panel").first().addClass('show');
    }
    // Creds man
    if ($('.up-sidenav-toggle').length > 0) {
      setUpmenu();
    }
    if($('.home-tiles__toggle').length > 0 && ww > 767) {
      $('.home-tiles').removeClass('home-tiles--open');
    }
  }

  // Initialise sliders
  function initSliders() {
    //Homepage slider
    $(".home-slider").not('.slick-initialized').slick({
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: false,
      slidesToShow: 1,
      speed: 500,
      dots: true
    });

    //Homepage slider
    $(".icons-slider__container").not('.slick-initialized').slick({
      autoplay: false,
      infinite: false,
      slidesToShow: 6,
      speed: 500,
      dots: false,
      responsive: [
        {
          breakpoint: 90000000,
          settings: "unslick"
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true
          }
        }
      ]
    });
  }
  initSliders();
  $(window).on('resize', initSliders);

  // Homepage - Mobile toggle tiles
  if($('.home-tiles__toggle').length > 0) {
    $('.home-tiles__toggle').on('click', function(e) {
      var $container = $(this).closest('.home-tiles');
      $container.toggleClass('home-tiles--open');
    });
  }

  // Homepage - Profile banner form
  if($('.profile-banner__form').length > 0) {
    $('.profile-banner__form').on('submit', function(e) {
      e.preventDefault()
      var url = $(this).attr('action'),
        searchParam = $(this).find('.profile-banner__form-input').val(),
        languageParam = $(this).find('.profile-banner__form-input').data('language'),
        urlWithQuery = url + '?query=' + searchParam + '&languages=' + languageParam;

      if (searchParam.length > 0) {
        window.open(urlWithQuery, '_blank');
      }
    });

    $('.profile-banner__form-search .profile-banner__form-submit').on('click', function(){
      $('.profile-banner__form').trigger('submit');
    });
    // Clear button
    $('.profile-banner__form-input').on('input', function(){
      var entered = $(this).val();
      if(entered.length > 0) {
        $(this).parent().addClass('profile-banner__form-input--active');
      } else {
        $(this).parent().removeClass('profile-banner__form-input--active');
      }
    });
    $('.profile-banner__form-search .profile-banner__form-clear').on('click', function() {
      var $input = $('.profile-banner__form-input');
      $(this).parent().removeClass('profile-banner__form-input--active');
      $input.val('')
    });
  }

  // Creds manager - user profile
  function toggleUpMenu() {
    $('.up-mobilenav').toggleClass('is-open');
  }

  $('.up-button-sidenav, .up-close-mobile-nav').on('click', function(){
    toggleUpMenu();
  });

  // Check device width - toggle creds mand mobile nav
  function setUpmenu() {
    var ww = $(window).width();

    if( ww > 992 ) {
      $('.up-sidenav-toggle').addClass('up-sidenav content up-scroll');
      $('.up-sidenav-toggle').removeClass('up-mobilenav is-open sticky');
    } else {
      $('.up-sidenav-toggle').addClass('up-mobilenav');
      $('.up-sidenav-toggle').removeClass('up-sidenav content up-scroll sticky');
      $('.up-sidenav__item--children-active').removeClass('up-sidenav__item--children-active');
    }
  }
  // sticky sidebar
//  function initScrollToggle() {
//    // Scroll to hide search bar in mobile header
//    var scrollContainer = $('.up-scroll').offset();
//    var maintenanceBanner = $('.maintenance-banner');
//    var notificationHeight = $('#notifications-div').length > 0 ? $('#notifications-div').height() : 0;
//    var stickyPoint = maintenanceBanner.length > 0 ? maintenanceBanner.outerHeight() + notificationHeight + 267 : notificationHeight + 267;
//
//    $(document).on('scroll', function() {
//      var y = $(this).scrollTop();
//      if (y > stickyPoint) {
//        $('.up-scroll').addClass('sticky');
//      } else {
//        $('.up-scroll').removeClass('sticky');
//      }
//    });
//  }

  function displayUpContent(dataTarget) {
    var targetSection = "#" + dataTarget + "-content";
    var targetTitle = "[data-title=" + dataTarget + "]";

    $('.up-content-section, .up-page-title__span').removeClass('show');
    $(targetSection).addClass('show');

    if(dataTarget === "about" || dataTarget === "reputation") {
      $(targetTitle).addClass('show');
    } else {
      $('[data-title="training"]').addClass('show');
    }
  }

  function highlightActiveStates(element) {
    var tabs = $('.up-sidenav__item');
    var activeTab = "[data-content=" + element + "]";

    tabs.removeClass('up-sidenav__item--active');
    $('[data-content]').removeClass('up-active');

    $(activeTab).addClass('up-active');
    $(activeTab).closest(tabs).addClass('up-sidenav__item--active');
  }

  // Live chat window
  // Toggle the
  function toggleChat($chat, $background) {
    $chat.toggleClass('live-chat--open');
    $background.toggleClass('live-chat-bg--open');
  }


  if($('.live-chat').length > 0) {
    var $toggleChatBtn = $('.live-chat__toggle-button'),
        $chatBg = $('.live-chat-bg'),
        $chatContainer = $('.live-chat'),
        $chatLink = $('.live-chat__link'),
        $chatAvailable = $("#lpChatButtonDiv1 div").hasClass("lp-agent-available"),
        $chatUserLoggedIn = $('.live-chat__contact-chat').hasClass('live-chat--login-true'),
        $caiWebChat = $('#cai-webchat-div'),
        $chatBotBtn = $('.live-chat__contact-bot-button'),
        $noChatBotMsg = $('.live-chat__contact-bot-unavailable'),
        $chatBotMsg = $('.live-chat__contact-bot-available');

    $toggleChatBtn.on('click',function(e){
      e.preventDefault();
      toggleChat($chatContainer, $chatBg);
    });

    $chatBg.on('click',function(e){
      toggleChat($chatContainer, $chatBg);
    });

    $chatLink.on('click',function(e){
      toggleChat($chatContainer, $chatBg);
    });
    // No chatbot available - IE11
    if($caiWebChat.length < 1) {
      $chatBotBtn.hide();
      $chatBotMsg.hide();
      $noChatBotMsg.show();
    }
    if ($chatAvailable && $chatUserLoggedIn) {
      $('.live-chat__contact-chat').addClass('live-chat__contact-chat--online');
    } else {
      $('.live-chat__contact-chat').removeClass('live-chat__contact-chat--online');
      // IE11 doesn't render the chatbot
      if($caiWebChat.length > 0) {
        //FadeIn BOT window when opening
        $('.live-chat__contact-bot-button').on('click', function() {
          setTimeout(function(){
            $('#cai-webchat-div').fadeIn('fast');
            $(document).find('.CaiAppExpander.open').trigger('click');
          }, 250);
        });
        //Fadeout BOT window when closing
        $('#cai-webchat-div').on('click', '.CaiAppHeader--btn', function(){
          $('#cai-webchat-div').fadeOut('fast');
        });
      }
    }
  }


  $('[data-content]').on('click', function() {
    var dataTarget = $(this).data('content');
    displayUpContent(dataTarget);
    highlightActiveStates(dataTarget);
    toggleUpMenu();
    upDisplayTabs();
  });

  // Creds tabs
  function upDisplayTabs(element) {
    var showTab = element ? element : 0;
    $('.up-tabs__trigger').removeClass('up-tabs__trigger--active');
    $('.up-tabs__content').removeClass('up-tabs__content--active');

    $('.up-tabs__trigger').eq(showTab).addClass('up-tabs__trigger--active');
    $('.up-tabs__content').eq(showTab).addClass('up-tabs__content--active');
  }
  // Update tab on click
  $('.up-tabs__trigger').on('click', function(e) {
    var tab = $(this).index('.up-tabs__trigger');
    upDisplayTabs(tab);
  });

  $("#activities").on('click', function(e) {
    upDisplayTabs(2);
  });

  // Initialise Creds
  if ($('.up-sidenav-toggle').length > 0) {
    setUpmenu();
//    initScrollToggle();
    upLoadmore();
  }

  // Load more
  function upLoadmore() {
    $('.up-load-more').each(function(){
      var parent = $(this),
        size_li = parent.find('.up-load-more__item').length,
        increment = 5;
      // // Show initial 5
      parent.find('.up-load-more__item:lt('+increment+')').show();
      parent.on('click', '.up-load-more__btn', function () {
        increment = (increment + 5 <= size_li) ? increment + 5 : size_li;
        parent.find('.up-load-more__item:lt('+ increment +')').show();
        if(size_li === increment) {
          $(this).hide();
        }
      });
    });
  }

  var hoverTimeout;
  $('.up-sidenav__item--children').on('mouseenter', function() {
    var self = $(this);
    //Wait a sec... Lets reset
    if (hoverTimeout) {
      hoverTimeout = clearTimeout(hoverTimeout);
    };
    //Ok, set the timeout and allow something to happen after 500 ms
    hoverTimeout = setTimeout(function() {
      self.find('ul').slideDown();
      self.addClass('up-sidenav__item--children-active');
    }, 500);
  });

  // End Creds manager - user profile

  // Language prompt
  $(document).on('click', '.language-alert-dismiss', function(e) {
    e.preventDefault();
    $(this).parents('#language-alert-container').remove();
    $.ajax({
      url: "/lang-notification/close",
      type: "get",
      error: function(data) {
        console.log("Error thrown closing notification: "+data);
      }
    });
  });

  var setupTranslationJS = function() {
    var formWrapper = $('.translate-form-wrapper'),
        form = formWrapper.find('.translate-form'),
        formElements = form.find('button[type="submit"], input, textarea, select'),
        languageSelector = $('.translate-lang');

    // Disable form by default
    formElements.prop("disabled", true);
    // Firefox hack - Needed to clear select field on page refresh
    languageSelector.prop('selectedIndex', 0);
  }

  if ($('.translate-form').length > 0 && $('.translate-form').hasClass('disabled')) {
    setupTranslationJS();
  }

  var updateTranslationAudit = function() {
    var translatedTimestampDest = $('.translation-updated'),
        translatedTimestampOrigin = $('.translation-updated-form');
    if (translatedTimestampOrigin.length > 0) {
      translatedTimestampDest.html(lodash.unescape(lodash.escape(translatedTimestampOrigin.html())));
    }
  }

  // Language selector
  $(document).on('change', '.translate-lang', function(){
    var iso = $(this).val().toUpperCase(),
        url = $(this).attr('data-url') + iso,
        formWrapper = $('.translate-form-wrapper'),
        form = formWrapper.find('.translate-form'),
        formElements = form.find('button[type="submit"], input, textarea, select');

    $.get({
      url: url,
      type: 'get',
      data:$(this).serialize(),
      success: function(data) {
        formWrapper.html(lodash.unescape(lodash.escape(data)));
        formElements.prop('disabled', false);
        form.removeClass('disabled');
        removeTinyMCE();
        initTinyMCE();

        $('#trans-delete').show();
        updateTranslationAudit();
      },
      error: function(data) {
        formWrapper.html(lodash.unescape(lodash.escape(data)));
      }
    });
  });

  $(document).on('submit', '.translate-form', function(e){
    e.preventDefault();
    var url = $(this).attr('action');
    var args = {
      url: url,
      type: 'post',
      data: $(this).serialize(),
      success: function(data) {
        $('.translate-form-wrapper').html(lodash.unescape(lodash.escape(data)));
        removeTinyMCE();
        initTinyMCE();

        updateTranslationAudit();
      },
      error: function(data) {
        console.log('Error > ', data);
      }
    }
    if ($(this).attr('enctype')) {
      var data = new FormData($(this)[0]);
      args.data = data;
      args.contentType = false;
      args.processData = false;
    }
    $.ajax(args);
  });

  $(document).on('click', '#trans-delete', function(e) {
    e.preventDefault();
    if (confirmDelete('Are you sure you want to delete this item?') == true) {
      var url = $(this).attr('href');
      if ($(this).hasClass('trans-ajax-delete')) {
        $.ajax({
          url: url,
          type: 'get',
          success: function(data) {
            $('.translate-form-wrapper-parent').html(lodash.unescape(lodash.escape(data)));
          }
        });
      } else {
        window.location.href = url;
      }
    }
  });
  // END Translate form

  // Cache Management
  $(document).on('change', '#cache-select', function(e) {
    $('#cache-clear-btn').show();
  });

  $(document).on('click', '#cache-clear-btn', function(e) {
    e.preventDefault();
    if (confirmDelete('Are you sure you want to clear this cache?') == true) {
      var url = $(this).attr('href');
      var selected = $('#cache-select').find(':selected').val();
      url = (url + '?name=' + selected);
      $.ajax({
        url: url,
        type: 'GET',
        success: function(data) {
          $('.alert-error').hide();
          $('.alert-success').html(lodash.unescape(lodash.escape(data)));
          $('.alert-success').show();
        },
        error: function(data) {
          $('.alert-success').hide();
          $('.alert-error').html(lodash.unescape(lodash.escape(data.responseText)));
          $('.alert-error').show();
        }
      });
    }
  });

  // Footer content + translation
  if($('.footer-translate-wrapper').length > 0){
    var formWrapper = $('.footer-translate-wrapper'),
        languageSelector = $('.footer-translate-lang');

    // Firefox hack - Needed to clear select field on page refresh
    if ($(this).hasClass('disabled')) {
      languageSelector.prop('selectedIndex', 0);
    }

    function loadFooterForm(obj) {
      var iso = obj.val().toUpperCase(),
          url = obj.attr('data-url') + iso;
      $.get({
        url: url,
        type: 'get',
        data:obj.serialize(),
        success: function(data) {
          formWrapper.html(lodash.unescape(lodash.escape(data)));
          formWrapper.removeClass('disabled');
        },
        error: function(data) {
          formWrapper.html(lodash.unescape(lodash.escape(data)));
        }
      });
    }

    // Language selector
    languageSelector.on('change', function(){
      loadFooterForm($(this));
    });

    $(document).ready(function() {
      if (languageSelector.val() != '') {
        loadFooterForm(languageSelector);
      }
    })

    $('.footer-translate-wrapper').on('click', '.footer-ajax-delete', function(e) {
      e.preventDefault();
      if (confirmDelete('Are you sure you want to delete this item?') == true) {
        var me = $(this).closest('.column-tile');
        $.get({
          url: $(this).attr('href'),
          success: function(data) {
            me.remove();
          }
        });
      }
    });

    $('.footer-translate-wrapper').on('click', '.footer-ajax-url', function(e) {
      e.preventDefault();
      var target = $(this).data('target');
      $.get({
        url: $(this).attr('href'),
        success: function(data) {
          $('#' + target).html(lodash.unescape(lodash.escape(data)));
          $('html, body').animate({
            scrollTop: $('#' + target).offset().top
          }, 'fast');
        }
      });
    });

    //close the tab content container
    $(document).on('click', '.footer-content-container__close', function(){
      $(this).parent().slideToggle();
    });

    $(document).on('submit', '.footer-form-submit', function(e) {
      e.preventDefault();
      var props = {
        url: $(this).attr('action'),
        method: "POST",
        data: $(this).serialize(),
        success: function(data){
          formWrapper.html(lodash.unescape(lodash.escape(data)));
        },
        error: function(data) {
          $('#footer-form').html(lodash.unescape(lodash.escape(data.responseText)));
        }
      }
      if ($(this).attr('enctype') == 'multipart/form-data') {
        props.data = new FormData($(this)[0]);
        props.contentType = false;
        props.processData = false;
      }
      $.ajax(props);
    })
  }

  // End Footer content


  $('.accordion-tabs').on('click',function(e){
    e.preventDefault();
    if ( $(window).width() > 767 ) {
      $(this).closest("div.row").find(".accordion-tabs.active").removeClass('active');
      $(this).closest("div.row").find(".panel.show").removeClass("show");
    }
    $(this).toggleClass('active');
    $(this).next('.panel').toggleClass("show");
  });

  // Trigger resize function on window resize
  $(window).resize( debounce( resize,100 ) );

  // Initialise Datepicker - needs jQuery, not $
  if (jQuery(".date-picker").length > 0) {
    jQuery(".date-picker").datepicker({ dateFormat: 'dd/mm/yy' });
  }

  //Hide notification
  if( $('.notification').length > 0 ){
    $('.notification').addClass('show');
    $('.notification__close').on('click', function(){
      $(this).closest('.notification').removeClass('show');
    });
  }

  //Hide maintenance notification
  if( $('.maintenance-banner').length > 0 ){
    $('.maintenance-banner').addClass('show');
    $('.maintenance-banner__close').on('click', function(){
      $(this).closest('.maintenance-banner').removeClass('show');
    });
  }

  //CMS - menu
  $('.menu-btn').on('click', function(){
    $('.menu').not($(this).parent()).removeClass('is-active');
    $(this).parent().toggleClass('is-active');
  });

  $(document).click(function(event) {
    var $target = $(event.target);
    if(!$target.closest('.menu').length && $('.menu').hasClass('is-active')) {
      $('.menu').removeClass('is-active');
    }
  });

  // toggle image select
  $(document).on('click', '.image-list__container', function(e){
    if (!($(e.target).hasClass('icon-delete') || $(e.target).hasClass('image-list__delete'))) {
      $('.image-list__container').removeClass('selected');
      $(this).addClass('selected');
      $('#img-submit').val($(this).find('img').attr('src'));
    }
  });

  $(document).on('click', '.image-list__delete', function(e) {
    e.preventDefault();
    var container = $(this).closest('.image-list__container');
    if (confirmDelete('Are you sure you wish to delete this image?')) {
      var url = $(this).attr('href');
      $.ajax({
        url: url,
        method: 'GET',
      });
      container.hide();
      if (container.hasClass('selected')) {
        $('#img-submit').val('');
        container.removeClass('selected');
      }
    }
  })

  //close the tab content container
  $(document).on('click', '.tab-content-container__close', function(){
    $(this).parent().slideToggle();
  });

  $(document).on('click', '.ajax-url', function(e){
    e.preventDefault();
    var target = $(this).data('target');
    $.get($(this).attr('href'), function(data) {
      $('#' + target).html(lodash.unescape(lodash.escape(data)));
    });
  });

  //  ajax submit form
  $(document).on('submit', '.ajax-submit', function(e){
    e.preventDefault();
    var target = $(this).data('target');
    var args = {
      url: $(this).attr('action'),
      type: 'post',
      data:$(this).serialize(),
      success: function(data) {
        window.location.reload();
      },
      error: function(data) {
        $('#' + target).html(lodash.unescape(lodash.escape(data.responseText)));
      }
    }
    if ($(this).attr('enctype')) {
      var data = new FormData($(this)[0]);
      args.data = data;
      args.contentType = false;
      args.processData = false;
    }
    $.ajax(args);
  });

  $(document).on('submit', '.tab-link-submit', function(e) {
    e.preventDefault();
    var failTarget = $(this).data('on-fail');
    var successTarget = $(this).data('on-success');
    var args = {
      url: $(this).attr('action'),
      type: 'post',
      data:$(this).serialize(),
      success: function(data) {
        $('.tab-content-container__close').parent().slideToggle();
        $('#' + successTarget).html(lodash.unescape(lodash.escape(data)));
      },
      error: function(data) {
        $('#' + failTarget).html(lodash.unescape(lodash.escape(data.responseText)));
      }
    }
    var data = new FormData($(this)[0]);
    args.data = data;
    args.contentType = false;
    args.processData = false;
    $.ajax(args);
  })


  //  edit tab link
  $('.edit-tab-link').on('click', function(e){
    e.preventDefault();
    var resource = $('#resource-id-col-2').val();
    var link = $(this).find('input').val();
    $.post('/'+resource+'/tab_link/'+link, {'column':0}, function(data){
      $('#tab-link-form').html(lodash.unescape(lodash.escape(data)));
    });
  });

  $(document).on('click', '.ajax-delete', function(e) {
    e.preventDefault();
    if (confirmDelete('Are you sure you want to delete this item?') == true) {
      var me = $(this).closest('.column-tile');
      $.get($(this).attr('href'), function(data) {
        me.remove();
      });
    }

  });

  $(document).on('click', '.ajax-clone', function(e) {
    e.preventDefault();
    if (confirmDelete('Are you sure you want to clone this item?') == true) {
      $.ajax({
        method: 'GET',
        url: $(this).attr('href'),
        success: function(data) {
          $('.error').hide();
          $('.success').html(lodash.unescape(lodash.escape(data)));
          $('.success').show();
        },
        error: function(data) {
          $('.success').hide();
          $('.error').html(lodash.unescape(lodash.escape(data.responseText)));
          $('.error').show();
        }
      })
    }

  });



  //  Delete object
  $('.tile__delete').on('click', function(e){
    e.preventDefault();
    var result = window.confirm('Are you sure you want to delete this item?');
    var link = $(this).attr('href');
    if (result == true) {
      $.ajax({
        url: link,
        type: 'delete',
        success: function(data) {
          window.location.reload();
        }
      });
    }
  });

  var confirmDelete = function(text) {
    var result = window.confirm(text);
    return result;
  }

  $(document).on('click', '.delete-confirmation', function(e) {
    e.preventDefault();
    if (confirmDelete('Are you sure you want to delete this item?') == true) {
      var url = $(this).attr('href');
      window.location.href = url;
      return true;
    } else {
      return false;
    }
  });

  $(document).on('click', '.dismiss-link', function(e) {
    e.preventDefault();
    if (confirm('Are you sure you want to dismiss these outdated translations?\nNote: this will not undo any changes made')) {
      var url = $(this).attr('href');
      var container = $(this).closest('.dismiss-change-alert');
      $.get({
        url: url,
        success: function(data) {
          container.hide();
        }
      })
    }
  })

  $(document).on('click', '#add-another-topic', function(e){
    $('.new-topic-name').show();
  });

  $(document).ready(function(){
    initSelect2();

    if (jQuery( '#topic-sortable').length > 0) {
      jQuery( '#topic-sortable').sortable({
        update: function(event, ui) {
          var topicList = $('.topic-name').map(function(){
            return $(this).data('topic-id');
          }).get();

          $.ajax({
            url: '/cms/help-centre/manage-topics-and-subtopics/order',
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify({'topicList': topicList}),
            success: function(data) {
              $('.alert-success').removeClass('hide');
              $('.alert-success').html('Your topic has been reordered.');
            },
          });
        }
      });
    }

    if (jQuery('.content-order').length > 0) {
      jQuery('.content-order').sortable({
        update: function(event, ui) {
          var sortableContainer = $(this);
          var contentList = sortableContainer.find('.sortable-pod').map(function(){
            return $(this).data('pod-id');
          }).get();
          var url = sortableContainer.data('order-url');
          var successClass = sortableContainer.data('success')
          $.ajax({
            url: url,
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify({'contentList': contentList}),
            success: function(data) {
              $('.'+successClass).removeClass('hide');
              $('.'+successClass+' span').html('Your content has been reordered.');
              if (sortableContainer.hasClass('module-sort')) {
                $('.module-pod').each(function(index) {
                  if ($('.module-pos-'+index).hasClass('blocked')) {
                    $(this).find('.block-warning').removeClass('hide');
                  } else {
                    $(this).find('.block-warning').addClass('hide');
                  }
                });
              }
            },
          });
        }
      });
    }

    refreshFaqsByTopic();

    initTinyMCE();

    var hash = location.hash;
    if (hash === '#resource-header') {
      $('html, body').animate({
        scrollTop: $('.resource-header-form').offset().top
      }, 'slow');
    }
  });

  $(document).on('click', '#add-another-subtopic', function(e){
    $('.new-subtopic-name').show();
    $('#subtopic-form-error').hide();
  });

  $(document).on('submit', '.subtopic-form', function(e){
    e.preventDefault();
    var url = $(this).attr('action');
    $.ajax({
      url: url,
      type: 'POST',
      data: $(this).serialize(),
      success: function(data) {
        $('.sub-topic-block').html(lodash.unescape(lodash.escape(data)));
      },
    });
  });

  $(document).on('click', '.topic-name', function(e){
    $('.sub-topic-block').show();
    $(this).parent('.topic-name').addClass('topic-clicked');
    $(this).parent('.topic-name').siblings().removeClass('topic-clicked');

    var topicId = $(this).data('topic-id');

    $.ajax({
      url: '/cms/help-centre/manage-topics-and-subtopics/show-subtopics',
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({'topicId': topicId}),
      success: function(data) {
        $('.sub-topic-block').html(lodash.unescape(lodash.escape(data)));

        jQuery( "#subtopic-sortable" ).sortable({
            update: function(event, ui) {
              var subTopicList = $('.sub-topic-name').map(function(){
                return $(this).data('subtopic-id');
              }).get();

              $.ajax({
                url: '/cms/help-centre/manage-topics-and-subtopics/sub-topic/order',
                type: 'POST',
                contentType: 'application/json; charset=utf-8',
                data: JSON.stringify({'subTopicList': subTopicList}),
                success: function(data) {
                  $('.alert-success').removeClass('hide');
                  $('.alert-success').html('Your sub-topic has been reordered.');
                },
              });
            }
          });
      },
    });
  });

  $(document).on('click', '.inline-edit-topic', function(e) {
    $(this).parents().siblings('.topic-name-text').addClass('hide');
    $(this).addClass('hide');
    $(this).parents().siblings('.inline-form-input').removeClass('hide');
    $(this).siblings('.inline-save-topic').removeClass('hide');
  });

  $(document).on('click', '.inline-save-topic', function(e) {
    e.preventDefault();
    var target = $(this);
    var url = $(this).attr('href');
    var topicId = $(this).parents('.topic-name').data('topic-id');
    var topicName = $(this).parent().siblings('.inline-form-input').find('.inline-input-topic').val();

    $.ajax({
      url: url,
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({'topicId': topicId, 'topicName': topicName}),
      success: function(data) {
        target.addClass('hide');
        target.siblings('.inline-edit-topic').removeClass('hide');
        target.parents().siblings('.topic-name-text').html(lodash.unescape(lodash.escape(topicName)));
        target.parents().siblings('.topic-name-text').removeClass('hide');
        target.parents().siblings('.inline-form-input').addClass('hide');
        target.parents().siblings('.topic-error').addClass('hide');
      },
      error: function(data) {
        target.parents().siblings('.topic-error').removeClass('hide');
        target.parents().siblings('.topic-error').html('Topic name is too long');
      }
    });
  });

  $(document).on('click', '.inline-edit-subtopic', function(e) {
    $(this).parents().siblings('.subtopic-name-text').addClass('hide');
    $(this).addClass('hide');
    $(this).parents().siblings('.inline-form-input').removeClass('hide');
    $(this).siblings('.inline-save-subtopic').removeClass('hide');
  });

  $(document).on('click', '.inline-save-subtopic', function(e) {
    e.preventDefault();
    var target = $(this);
    var url = $(this).attr('href');
    var subTopicId = $(this).parents('.sub-topic-name').data('subtopic-id');
    var subTopicName = $(this).parent().siblings('.inline-form-input').find('.inline-input-subtopic').val();

    $.ajax({
      url: url,
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({'subTopicId': subTopicId, 'subTopicName': subTopicName}),
      success: function(data) {
        target.addClass('hide');
        target.siblings('.inline-edit-subtopic').removeClass('hide');
        target.parents().siblings('.subtopic-name-text').html(lodash.unescape(lodash.escape(subTopicName)));
        target.parents().siblings('.subtopic-name-text').removeClass('hide');
        target.parents().siblings('.inline-form-input').addClass('hide');
        target.parents().siblings('.subtopic-error').addClass('hide');
      },
      error: function(data) {
        target.parents().siblings('.subtopic-error').removeClass('hide');
        target.parents().siblings('.subtopic-error').html('Sub-topic name is too long');
      }
    });
  });

  $(document).on('change', '#select-topic', function(e) {
    var url = $(this).data('url');
    var topicId = $(this).val();

    refreshSubtopicDropdown(url, topicId);
  });

  $(document).ready(function(e) {
    var topic = $('#select-topic');
    var url = topic.data('url');
    var topicId = topic.val();
    refreshSubtopicDropdown(url, topicId);
  });

  function refreshSubtopicDropdown(url, topicId) {
    if (topicId != null) {
      $.ajax({
        url: url,
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify({'topicId': topicId}),
        success: function(data) {
          const selectedSubTopicID = lodash.unescape(lodash.escape($('#select-subtopic').val()));

          $('#select-subtopic').html(lodash.unescape(lodash.escape(data)));

          if ($('#select-subtopic option[value='+selectedSubTopicID+']').length > 0) {
            $('#select-subtopic').val(selectedSubTopicID);
          }
        },
      });
    }
  }

  function refreshSubtopicList(topicId) {
    $.ajax({
      url: $('#subtopic-filter').data('refresh'),
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({'topicId': topicId}),
      success: function(data) {
        $('#subtopic-filter').html(lodash.unescape(lodash.escape(data)));
      }
    });
  };

  function refreshFaqsByTopic() {
    var url = $('#topic-filter').data('url');
    if (url) {
      var topicId = $('#topic-filter').val();

      if ($('#subtopic-filter').val() != null) {
        var subTopicId = $('#subtopic-filter').val();
        var data = JSON.stringify({'topicId': topicId, 'subTopicId': subTopicId});
      } else {
        var data = JSON.stringify({'topicId': topicId, 'subTopicId': 0});
      }

      $.ajax({
        url: url,
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: data,
        success: function(data) {
          $('#item-list').html(lodash.unescape(lodash.escape(data)));
          refreshSubtopicList(topicId);
        }
      });
    }
  }

  $(document).on('change', '#topic-filter', function(e) {
    refreshFaqsByTopic();
  });

  $(document).on('change', '#subtopic-filter', function(e) {
    var subTopicId = $(this).val();
    var url = $(this).data('url');

    if ($('#topic-filter').val() != null) {
      var topicId = $('#topic-filter').val();
      var data = JSON.stringify({'topicId': topicId, 'subTopicId': subTopicId});
    } else {
      var data = JSON.stringify({'topicId': 0, 'subTopicId': subTopicId});
    }

    $.ajax({
      url: url,
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      data: data,
      success: function(data) {
        $('#item-list').html(lodash.unescape(lodash.escape(data)));
      }
    });
  });

  $(document).on('click', '.topic-menu-link', function(e) {
    $(this).find('.expand_more').toggleClass('hide');
    $(this).find('.chevron_right').toggleClass('hide');

    if ($(this).find('.chevron_right').hasClass('hide') == true) {
      $(this).parent('.topic-menu').css('border-bottom', 'none');
    }

    $(this).parent('.topic-menu').next('.subtopic-menu').find('li').last().css('border-bottom', 'none');
  });

  $(document).on('click', '.subtopic-menu-link', function(e) {
    e.preventDefault();

    $(this).parent('li').addClass('yellow-menu');
    $('.subtopic-menu-item').not($(this).parent('.subtopic-menu-item')).removeClass('yellow-menu');

    var url = $(this).attr('href');
    var subTopicId = $(this).data('subtopic-id');

    $.ajax({
      url: url,
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({'subTopicId': subTopicId}),
      success: function(data) {
        $('#topic-articles').html(lodash.unescape(lodash.escape(data)));
      }
    });
  });

  $(document).on('click', '.load-more-articles', function(e) {
    e.preventDefault();

    var url = $(this).attr('href');
    var rowsOfArticle = $('.article-list-item').length;
    var articleSubtopicId = $(this).data('article-subtopic-id');
    var totalNumOfArticles = $('#total-num-of-articles').data('total-num-of-articles');

    $.ajax({
      url: url,
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({'rowsOfArticle': rowsOfArticle, 'articleSubtopicId': articleSubtopicId}),
      success: function(data) {
        $('.article-list').append(lodash.unescape(lodash.escape(data)));

        var displayedNumOfArticles = $('.article-list-item').length;
        if (displayedNumOfArticles == totalNumOfArticles) {
          $('.load-more-articles').addClass('hide');
        }
      }
    });
  });

  $(document).on('click', '.video-slide', function(){
    var videoID = $(this).data('videoid'),
      content = '';

    if (videoID.indexOf('youtube') > 0) {
      videoID = videoID.split("watch?v=").pop(-1);
      content = '<iframe width="560" height="315" src="https://www.youtube.com/embed/'+videoID+'" frameborder="0" allowfullscreen></iframe>';
    } else if(videoID.indexOf('vimeo') > 0) {
      videoID = videoID.split("/").pop(-1);
      content = '<iframe width="640" height="336" src="https://player.vimeo.com/video/'+videoID+'" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
    }
  //    else if(videoType == 'sap') {
  //      content = '<div class="video-js"><video controls><source src="'+videoID+'" type="video/mp4"></video></div>';
  //    }

    $('#modalPopupBodyVideo .video-container').html(content);
    jQuery('#modalPopupVideo').modal('show').css('display', 'block !important');
  });

  $(document).on('click', '.save-faq', function(e) {
    e.preventDefault();
    var $form = $('.faq-form');

    var friendlyUrlField = $('#faq-form-friendly-url');
    if ($('#faq-form-id').val().length == 0 && friendlyUrlField.val().length == 0) {
      var cleaned = $('#faq-form-title').val().replace(/[^a-zA-Z 0-9]+/g, '').replace(" ", "-");
      friendlyUrlField.val(cleaned.toLowerCase());
    }

    if (isTinyMceApplicable()) {
      tinymce.triggerSave();
    }

    //Replace faq block array placeholders and set position values
    $('.faq-block').each(function(index) {
      $(this).find('.faq-block-field').each(function() {
        var replaceWith = "blocks[" + index + "]";
        var fieldName = $(this).attr("name").replace(/blocks\[.*\]/, replaceWith);
        $(this).attr("name", fieldName);

        if (fieldName == replaceWith + ".position") {
          $(this).val(index);
        }

      });
    })
    $form.submit();
  });

  if (jQuery(".faq-block-container").length > 0) {
    jQuery(".faq-block-container").sortable({
      axis: "y",
      handle: ".unfold_more-faq-block",
      opacity: 0.5,
      stop: function(e, ui) {
        removeTinyMCE();
        initTinyMCE();
      }
    });
  }

  $(document).on("click", ".add-faq-text-block", function(e) {
    e.preventDefault();
    $.get("/cms/help-centre/manage-faqs/faq/text-block", function(data) {
      $(".faq-block-container").append(lodash.unescape(lodash.escape(data)));
      removeTinyMCE();
      initTinyMCE();
    });
  });

  $(document).on("click", ".add-faq-image-block", function(e) {
    e.preventDefault();
    $.get("/cms/help-centre/manage-faqs/faq/image-block", function(data) {
      $(".faq-block-container").append(lodash.unescape(lodash.escape(data)));
    });
  });

  $(document).on("click", ".faq-image-upload-button", function (e) {
    e.preventDefault();
    var container = $(this).parents(".faq-image");
    var form = $(this).parents(".faq-image-upload-form")[0];

    if (!form) {
      // in some cases the HTML is missing <form> when rendering faq-image-block.jinja,
      // to make it working - get faq image HTML and wrap into form
      // clear faq image and append new HTML wrapped into <form>
      const faqImage = $(this).parents(".faq-image-upload");
      const faqImageChildren = $(this).parents(".faq-image-upload").children();
      faqImage.empty();

      const formHTML = $("<form class='faq-image-upload-form'></form>").append(faqImageChildren);
      faqImage.html(formHTML);

      form = $(this).parents(".faq-image-upload-form")[0];
    }

    var data = new FormData(form);

    $.ajax({
      url: "/cms/help-centre/manage-faqs/faq-image/upload",
      type: 'POST',
      enctype: 'multipart/form-data',
      processData: false,
      contentType: false,
      cache: false,
      data: data,
      success: function(data) {
        container.fadeOut(100);
        container.replaceWith(lodash.unescape(lodash.escape(data)));
        container.fadeIn(1000);
      },
      error: function(dta) {
      }
    });
  });

  $(document).on("click", ".delete-faq-block", function(e) {
    e.preventDefault();
    var block = $(this).closest(".faq-block");
    block.animate({height: "toggle", opacity: "toggle"}, 600, function() {
      $(this).remove();
    });
  });

  $('.accordion-type-select').on('change', function() {
    var content = $(this).val(),
        contentContainer = $('.accordion-type-content'),
        url = $(this).attr('data-url') + content;
    $.get({
      url: url,
      type: 'get',
      success: function(data) {
        contentContainer.html(lodash.unescape(lodash.escape(data)));
        initSelect2();
      },
      error: function(data) {
        contentContainer.html(lodash.unescape(lodash.escape(data)));
      }
    });
  });

  $(document).on('click', '#show-expired', function(e) {
	 e.preventDefault();
	 var showExpired = $('#show-expired').data('show-expired')
	 $.ajax({
      url: '/profile/subscriptions/'+!showExpired,
      type: 'GET',
      success: function(data) {
        $('#subscription-list-block').html(lodash.unescape(lodash.escape(data)));
      },
      error: function(data) {
        console.log(data);
      }
	 });
  });

  $(document).on("click", "#close-notification-message", function(e) {
    e.preventDefault();
    $("#notification-message").remove();
  });

  function initTinyMCE() {
	  if (typeof window.tinymce == 'undefined') {
	  	return;
    }
	  var basicArgs = {
      selector: '.basic-editor',
      urlconverter_callback : 'myCustomURLConverter',
      menubar: false,
      statusbar: false,
      plugins: "link, lists",
      height: 200,
      width: 600,
      toolbar: "bold,italic,underline,|,undo,redo,link,unlink"
    };

    if ($('.basic-editor').attr('disabled')) {
      basicArgs.readonly = 1;
    }
    tinymce.init(basicArgs);

    var advancedArgs = {
      selector: '.advanced-editor',
      urlconverter_callback : 'myCustomURLConverter',
      menubar: false,
      statusbar: false,
      plugins: "link, lists,textcolor",
      height: 200,
      width: 600,
      toolbar: "bold,italic,underline,formatselect,bullist,|,undo,redo,link,unlink,|,forecolor,subscript,superscript,fontsizeselect,numlist",
      block_formats: 'Paragraph=p; Header 2=h2; Header 3=h3'
    }

    if ($('.advanced-editor').attr('disabled')) {
      advancedArgs.readonly = 1;
    }
    tinymce.init(advancedArgs);
  }

  function isTinyMceApplicable(){
    return ($(".basic-editor").length > 0 || $(".basic-editor-links").length > 0) ||
    ($(".advanced-editor").length > 0 || $(".advanced-editor-links").length > 0);
  }

  function removeTinyMCE() {
    if (isTinyMceApplicable() && tinymce.editors.length > 0) {
      tinymce.remove();
    }
  }

  function initSelect2() {
    jQuery('.multiple-select').select2({
      closeOnSelect: false,
      containerCssClass: ':all:',
    });
    jQuery('.multiple-select').on("select2:select", function (e) {
      var element = e.params.data.element;
      jQuery(element).detach();
      jQuery(this).append(jQuery(element));
      jQuery(this).trigger("change");
    });
  }

  function myCustomURLConverter(url, node, on_save, name) {
      var myUrl = url;
      if (url.indexOf("http://") == -1) {
          myUrl = "http://www.".concat(myUrl);
      }
      if (url.indexOf("https://") > -1) {
          myUrl = url;
      }
      return myUrl;
  }


  $('.ae-select').on('change', function(e) {
      e.preventDefault();
      var field = $(this).data('select-type');
      if ($('#' + field + '-option-other').is(':selected')) {
          $('#' + field + '-input-other').removeAttr('disabled')
      } else {
    	  $('#' + field + '-input-other').val('')
          $('#' + field + '-input-other').attr('disabled', 'disabled')
      }
  });

  // GDPR consents display
  $('#GeneralMarketing').on('click', function(e) {
	  if (!$('.consent-marketing').is(':checked')) {
		  $('.consent-marketing-option').prop('checked', false);
		  $('.consent-marketing-option').attr('disabled', 'disabled');
	  } else {
		  $('.consent-marketing-option').removeAttr('disabled');
	  }
  });
  $(document).ready(function(){
    var status = false;
    $(".consent-marketing-option").each(function(i,v){
      if($(this).is(':checked')){
       status = true;
      }
    });
    if(!status){
      $('.consent-marketing-option').attr('disabled', 'disabled');
    } else {
      $('#GeneralMarketing').prop('checked',true);
    }
  });

  //Editions
  $(document).on('click', '.inline-edit-edition', function(e){
    $('.edition-block').show();
    $('.edition-name').removeClass('edition-clicked');
    $(this).closest('.edition-name').addClass('edition-clicked');
    var editionTab = $(this).closest('.edition-name')

    var editionId = $(this).data('edition-id');
    $('.edition-block').css({'position':'relative','top':editionTab.position().top});
    $.ajax({
      url: '/cms/administration/manage-editions/show-edition',
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({'editionId': editionId}),
      success: function(data) {
        $('.edition-block').html(lodash.unescape(lodash.escape(data)));
      },
    });
  });

  $(document).on('click', '#add-another-edition', function(e){
    $('.new-edition-name').show();
  });

  $(document).on('click', '.cancel-edition-form', function(e){
      $('.edition-block').hide();
  });

  $(document).on('click', '.cancel-add-edition', function(e){
      e.preventDefault();
      $('.new-edition-name').hide();
  });

  $(document).on('submit', '#edit-block-form', function(e){
    e.preventDefault();
    var url = $(this).attr('action');
    $.ajax({
      url: url,
      type: 'POST',
      data: $(this).serialize(),
      success: function(data) {
        $('#alert-edit-success').text(data).show();
        $('#alert-edit-error').hide();
        location.reload();
      },
      error: function(xhr, status, error){
        $('#alert-edit-error').text(xhr.responseText).show();
        $('#alert-edit-success').hide();
      }
    });
  });

  $(document).ready(function(){
    if (jQuery( '#edition-sortable').length > 0) {
      jQuery( '#edition-sortable').sortable({
        update: function(event, ui) {
          var editionList = $('.edition-name').map(function(){
            return $(this).data('edition-id');
          }).get();

          $.ajax({
            url: '/cms/administration/manage-editions/order',
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify({'editionList': editionList}),
            success: function(data) {
              $('.alert-success').removeClass('hide');
              $('.alert-success').html('Your edition has been reordered.');
            },
          });
        }
      });
    }

    if (jQuery('#accordion-sortable').length > 0) {
      jQuery('#accordion-sortable').sortable({
        update: function(event, ui) {
          var accordionList = $('.accordion-name').map(function(){
            return $(this).data('accordion-id');
          }).get();

          $.ajax({
            url: '/cms/help-centre/manage-page/accordions/order',
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify({'accordionList': accordionList}),
            success: function(data) {
              $('.alert-success').removeClass('hide');
              $('.alert-success').html('Your accordion has been reordered.');
            },
            error: function(data) {
              console.log('Error: ', data);
            }
          });
        }
      });
    }

    if (jQuery('.list-sortable').length > 0) {
      jQuery('.list-sortable').sortable({
        handle: '.drag-icon',
        update: function(event, ui) {
          var idList = $('.list-object').map(function(){
            return $(this).data('id');
          }).get();
          var url = $('.list-sortable').data('sort-url');
          $.ajax({
            url: url,
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify({'idList': idList}),
            success: function(data) {
              $('.alert-danger').addClass('hide');
              $('.alert-success').removeClass('hide');
              $('.alert-success').html('Your list has been reordered.');
            },
            error: function(data) {
              $('alert-success').addClass('hide');
              $('alert-danger').html(lodash.unescape(lodash.escape(data.responseText)));
              $('alert-danger').removeClass('hide');
            }
          });
        }
      });
    }
  });


  //  //Users
  $(document).ready(function() {
    var paramString = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&'), i, val;
    for (i=0; i<paramString.length; i++) {
      val = paramString[i].split('=');
      if (val[0] == 'openUser') {
        var id = val[1];
        openUserDetails(id);
        $('html, body').animate({
          scrollTop: $(lodash.unescape(lodash.escape('.user-' + id))).offset().top
        }, 'slow');
      }
    }
  });

  $(document).on('click', '.inline-edit-user', function(e){
    closeUserDetails($('#open-user'));
    openUserDetails($(this).data('user-id'));
  });

  $(document).on('click', '.close-user-btn', function(e) {
    e.preventDefault();
    closeUserDetails($('#open-user'));
  })

  function openUserDetails(userId) {
    $.ajax({
      url: '/cms/user-administration/manage-users/show-user',
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({'userId': userId}),
      success: function(data) {
        $(lodash.unescape(lodash.escape('.user-' + userId))).html(lodash.unescape(lodash.escape(data)));
      },
    });
  }

  function closeUserDetails(element) {
    var userId = element.data('user-id');
    $.ajax({
      url: '/cms/user-administration/manage-users/close-user',
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({'userId': userId}),
      success: function(data) {
        $('.user-' + userId).html(lodash.unescape(lodash.escape(data)));
      },
    });
  }

  $(document).on('click', '#add-another-user', function(e){
    $('.new-user-name').show();
  });

  $(document).on('submit', '#edit-user-form', function(e){
    e.preventDefault();
    var url = $(this).attr('action');

    var userId = document.getElementById('form-user-id').getAttribute('value');

    var roleCheckboxes = $(".user-role-checkbox:checked").map(function(){
      return $(this).val();
    }).get();
    var editionCheckboxes = $(".user-edition-checkbox:checked").map(function(){
      return $(this).val();
    }).get();
    var languageCheckboxes = $(".user-language-checkbox:checked").map(function(){
      return $(this).val();
    }).get();
    var defaultLanguage = $(".default-lang-select option:selected").val();

    var data = {
      id: userId,
      roleList: roleCheckboxes,
      editionList: editionCheckboxes,
      languageList: languageCheckboxes,
      defaultLanguage: defaultLanguage
    };

    $.ajax({
      url: url,
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(data),
      success: function(data) {
        $(lodash.unescape(lodash.escape('.user-' + userId))).html(lodash.unescape(lodash.escape(data)));
      }
    });
  });

  $(document).on('change', '.trans-checkbox', function(e) {
    var checked = this.checked;
    if (!checked) {
      $('.user-language-checkbox').prop('checked', false);
      $('.default-lang-select option:selected').prop('selected', false);
      $('.default-lang-select option:first').prop('selected', 'selected');
    }
    $('.user-language-checkbox').attr('disabled', !checked);
    $('.default-lang-select').attr('disabled', !checked);
  });

  $(document).on('click', '.edit-resource-header', function(e) {
    $(this).addClass('hide');
    var name = $('.resource-name').addClass('hide').text();
    $('.resource-name-input').toggleClass('hide d-xs-inline-block').val(name);
    $('.save-resource-header').removeClass('hide');
  });

  $(document).on('click', '.save-resource-header', function(e) {
    e.preventDefault();
    $(this).addClass('hide');
    var name = $('.resource-name-input').toggleClass('hide d-xs-inline-block').val().trim();
    if(name != ''){
      $('.resource-name').text(name);
    }
    $('.resource-name').removeClass('hide');
    $('.edit-resource-header').removeClass('hide');

    var form = $('.resource-header-form');
    $.ajax({
      url: form.attr('action'),
      type: form.attr('method'),
      data: form.serialize(),
      success: function(data) {
        $('#alert-edit-error').hide();
        $('#alert-edit-success').show();
        var body = JSON.parse(data);
        if (body.headerDismiss) {
          $('.dismiss-link').attr('href', lodash.unescape(lodash.escape(body.headerDismiss)))
          $('#alert-edit-dismiss').show();
        }
      },
      error: function(xhr, status, error){
        $('#alert-edit-success').hide();
        $('#alert-edit-error').text(xhr.responseText).show();
      }
    });
  });

  $('.resource-header-form').on('keyup keypress', function(e) {
    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      e.preventDefault();
      return false;
    }
  });

  // Show translate dropdown for resource management
  $('.translate-resource-header').on('click', function(e){
    e.preventDefault();
    $('.translate-section-1').removeClass('hide');
  });
  // Select language to translate
  $('.translate-header').on('change', function(){
    var iso = $(this).val().toUpperCase(),
        url = $(this).attr('data-url') + iso;

    $.get({
      url: url,
      cache: false,
      data:$(this).serialize(),
      success: function(data) {
        const escapedData = lodash.unescape(lodash.escape(data));
        $('.translate-section-2').removeClass('hide');
        $('.resource-name-label-translate').text(iso);
        $('#isoCode').val(iso);
        if(escapedData.headerTranslation){
          $('.resource-name-input-translate').val(escapedData.headerTranslation);
          $('.resource-header-form-translate .delete-resource-header-confirmation').removeClass('hide');
        } else {
          $('.resource-name-input-translate').val('');
          $('.resource-header-form-translate .delete-resource-header-confirmation').addClass('hide');
        }
        if(escapedData.transId){
          $('#transId').val(escapedData.transId);
          var deleteUrl = $('.resource-header-form-translate .delete-resource-header-confirmation').attr('href');
          var regex = new RegExp('/[^/]*$');
          var urlWithIso = lodash.unescape(lodash.escape(deleteUrl)).replace(regex, '/') + escapedData.transId;
          $('.resource-header-form-translate .delete-resource-header-confirmation').attr('href', urlWithIso);
        }
      },
      error: function(data) {
        console.log('Error: ', data)
      }
    });
  });

  $(document).on('click', '.delete-resource-header-confirmation', function(e) {
    e.preventDefault();
    var result = window.confirm('Are you sure you want to delete this item?');
    var url = $(this).attr('href');

    if (result == true) {
      $.post({
        url: url,
        success: function(data) {
          window.location.reload();
        },
        error: function(data){
          console.log('Error');
        }
      });
    }
  });






  // Post translation
  $('.save-resource-header-translate').on('click', function(e){
    e.preventDefault();
    var form = $(this).closest('form'),
        url = form.attr('action');

    $.post({
      url: url,
      data: form.serialize(),
      success: function(data) {
        $('#alert-edit-success').text(data).show();
        $('#alert-edit-error').hide();
        $('.translate-section-1').addClass('hide');
        $('.translate-section-2').addClass('hide');
        $('.translate-header').val('');
      },
      error: function(xhr,data){
        $('#alert-edit-error').text(xhr.responseText).show();
          $('#alert-edit-success').hide();
      }
    })
  });

  // CMS - manage notification form
  $('#dtn-pageRequired').on('click', function(e) {
    if ($(this).prop('checked') === true) {
      $('.disable-required').each(function(i) {
        $(this).removeAttr('disabled');
      });
    } else {
      $('.disable-required').each(function(i) {
        $(this).attr('disabled', 'disabled');
      });
    }
  });

  $('#dtn-specificPageRequired').on('click', function(e) {
      if ($(this).prop('checked') === true) {
        $('.disable-required-page').each(function(i) {
          $(this).removeAttr('disabled');
        });
                $('.disable-required-edition').each(function(i) {
                  $(this).attr('disabled', 'disabled');
                });
      } else {
        $('.disable-required-page').each(function(i) {
          $(this).attr('disabled', 'disabled');
        });
        $('.disable-required-edition').each(function(i) {
          $(this).removeAttr('disabled');
        });
      }
    });

  // CMS - manage object list (country, language, etc)
  $(document).on('click', '.inline-edit-obj', function(e){
    e.preventDefault();
    $('.obj-name').removeClass('obj-clicked');
    $(this).closest('.obj-name').addClass('obj-clicked');
    var objTab = $(this).closest('.obj-name')

    var objUrl = $(this).attr('href');
    $('.obj-block').css({'position':'relative','top':objTab.position().top});
    $.ajax({
      url: objUrl,
      cache: false,
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      success: function(data) {
        $('.obj-block').html(lodash.unescape(lodash.escape(data)));
        $('.obj-block').show();
        if ($('.translate-form').length > 0 && $('.translate-form').hasClass('disabled')) {
          setupTranslationJS();
        }
      },
    });
  });

  $(document).on('click', '.cancel-obj-form', function(e) {
    $('.obj-block').hide();
    $('.obj-name').removeClass('obj-clicked');
  });

  $(document).on('click', '.add-another-obj', function(e) {
    e.preventDefault();
    $('.obj-creation-form').show();
  });

});

// Spinner
var General = {

  generateSpinnerElement: function(length, width, radius, top, left) {
    if (typeof(length) === 'undefined') length = 26;
    if (typeof(width) === 'undefined') width = 9;
    if (typeof(radius) === 'undefined') radius = 29;
    if (typeof(top) === 'undefined') top = 'auto';
    if (typeof(left) === 'undefined') left = 'auto';

    // Animated loader
    var opts = {
      lines: 17, // The number of lines to draw
      length: length, // The length of each line
      width: width, // The line thickness
      radius: radius, // The radius of the inner circle
      corners: 0, // Corner roundness (0..1)
      rotate: 0, // The rotation offset
      color: '#000', // #rgb or #rrggbb
      speed: 1.1, // Rounds per second
      trail: 100, // Afterglow percentage
      shadow: false, // Whether to render a shadow
      hwaccel: false, // Whether to use hardware acceleration
      className: 'spinner', // The CSS class to assign to the spinner
      zIndex: 2e9, // The z-index (defaults to 2000000000)
      top: top, // Top position relative to parent in px
      left: left // Left position relative to parent in px
    };
    var spinner = new Spinner(opts).spin();

    return spinner.el;
  }
};
